import React from "react"
import { createUseStyles } from "react-jss"
import { Button, Container } from "react-bootstrap"

const Bottom = () => {
  const styles = useStyles()
  return (
    <Container className={styles.top}>
      <div className={styles.heading}>
        <h1>ANTI-Money Laundering</h1>
        <p>
          ANTI-Money LaunderingWe have built-In & updated Anti-Money laundering
          system in Payoasis.
          <br /> We ensure that every transaction is legitimate and adheres to
          any illegal money transfer practices.
          <br /> We take care of everything you do in the achieving your
          business goals
        </p>

        <div className={styles.btnDiv}>
          <Button variant="outline-danger" className={styles.btn}>
            LEARN MORE
          </Button>
        </div>
      </div>
    </Container>
  )
}
const useStyles = createUseStyles({
  heading: {
    marginBottom: 100,
    textAlign: "center",
  },
  top: {
    marginTop: 100,
    fontFamily: "poppins",
  },
  img: { marginRight: 100 },
  btn: {
    width: 150,
    fontSize: 15,
    display: "flex",
    justifyContent: "center",
  },

  btnDiv: {
    fontFamily: "poppins",
    display: "flex",
    justifyContent: "center",
  },
})
export default Bottom
