import React from "react"
import { createUseStyles } from "react-jss"
import { Button, Col, Container, Row } from "react-bootstrap"
import card1 from "../../assets/card1.png"
import card2 from "../../assets/card2.png"
import card3 from "../../assets/card3.png"
import card4 from "../../assets/card4.png"
import { Fade } from "react-reveal"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Card = ({
  firstTxt,
  firstDesc,
  secDesc,
  secTxt,
  thirdTxt,
  thirdDesc,
  FourthTxt,
  FourthDesc,
}) => {
  const styles = useStyles()
  return (
    <>
      <div className={styles.top} style={{ paddingBottom: 50 }}>
        <Container>
          <Fade bottom>
            <Row>
              <Col lg="6">
                <div className={styles.card}>
                  <div className={styles.img}>
                    <LazyLoadImage src={card1} />
                  </div>
                  <div>
                    <h1 className={styles.cardTitle}>{firstTxt}</h1>
                    <p className={styles.pText}>
                      {firstDesc.length > 80
                        ? `${firstDesc.substring(0, 80)}...`
                        : firstDesc}
                    </p>
                    <div className={styles.btnDiv}>
                      <Button variant="outline-danger" className={styles.btn}>
                        LEARN MORE
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg="6">
                <div className={styles.card}>
                  <div className={styles.img}>
                    <LazyLoadImage src={card2} />
                  </div>
                  <div>
                    <h1 className={styles.cardTitle}>{secTxt}</h1>
                    <p className={styles.pText}>
                      {secDesc.length > 80
                        ? `${secDesc.substring(0, 80)}...`
                        : secDesc}
                    </p>
                    <div className={styles.btnDiv}>
                      <Button variant="outline-danger" className={styles.btn}>
                        LEARN MORE
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg="6">
                <div className={styles.card}>
                  <div className={styles.img}>
                    <LazyLoadImage src={card3} />
                  </div>
                  <div>
                    <h1 className={styles.cardTitle}>{thirdTxt}</h1>
                    <p className={styles.pText}>
                      {thirdDesc.length > 80
                        ? `${thirdDesc.substring(0, 80)}...`
                        : thirdDesc}
                    </p>
                    <div className={styles.btnDiv}>
                      <Button variant="outline-danger" className={styles.btn}>
                        LEARN MORE
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg="6">
                <div className={styles.card}>
                  <div className={styles.img}>
                    <LazyLoadImage src={card4} />
                  </div>
                  <div>
                    <h1 className={styles.cardTitle}>{FourthTxt}</h1>
                    <p className={styles.pText}>
                      {FourthDesc.length > 80
                        ? `${FourthDesc.substring(0, 80)}...`
                        : FourthDesc}
                    </p>
                    <div className={styles.btnDiv}>
                      <Button variant="outline-danger" className={styles.btn}>
                        LEARN MORE
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Fade>
        </Container>
      </div>
    </>
  )
}
const useStyles = createUseStyles({
  top: {
    marginTop: 50,
    fontFamily: "poppins",
  },
  img: {
    marginRight: 30,
  },
  cardTitle: {
    alignContent: "center",
    alignItems: "center",
    fontSize: 25,
    padding: 0,
    marginTop: 10,
    marginBottom: 0,
  },
  pText: {
    marginTop: 10,
  },
  btn: {
    width: 150,
    fontSize: 15,
  },
  btnDiv: {
    fontFamily: "poppins",
  },
  card: {
    borderTop: "5px solid #F2655F",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 30,
    boxShadow: " 4px 7px 26px rgba(0, 0, 0, 0.1)",
    background: "white",
    padding: 40,
    borderRadius: 25,
    "&:hover": {
      transform: "scale(1.03)",
      boxShadow: " 4px 7px 26px rgba(0, 0, 0, 0.12)",
    },
  },

  "@media (max-width: 992px)": {
    top: {
      margin: 10,
      marginTop: 80,
    },

    heading: {
      textAlign: "center",
      marginBottom: 50,
    },

    card: {
      marginTop: 20,
    },
  },
  "@media (max-width: 650px)": {
    card: {
      flexDirection: "column",
    },
    cardTitle: {
      marginTop: 20,

      textAlign: "center",
    },
    pText: {
      textAlign: "center",
    },
    btn: {
      display: "flex",
      justifyContent: "center",
    },
    btnDiv: {
      display: "flex",
      justifyContent: "center",
    },
  },
})
export default Card
