import React from "react"
import "antd/dist/antd.css"
import "../../styles/Home.css"
import "../../styles/style.css"
// import Intro from "../Components/Home/Intro"
import "bootstrap/dist/css/bootstrap.min.css"
import Banner from "../Components/Home/Banner"
import Layout from "../Components/Layout/Layout"
import Infography from "../Components/Home/Infography"

const Index = () => {
  return (
    <div>
      <title>Payoasis</title>
      <Layout />
      {/* <Intro /> */}
    </div>
  )
}
export default Index
