import React from "react"
import { Button } from "react-bootstrap"
import Video from "../../assets/Video1.mp4"
import Fade from "react-reveal/Fade"
import useWindowSize from "../hooks/WindowSize"
import { createUseStyles } from "react-jss"
import Banner2 from "./responsive/Banner2"
import { Link } from "gatsby"

const Banner = () => {
  const styles = useStyles()
  const { width } = useWindowSize()
  return (
    <>
      {width > 900 ? (
        <div className={styles.container}>
          <div className={styles.textPadding}>
            <Fade bottom>
              <p className={styles.bannerText}>
                Easy & Affordable
                <br />
                Banking
              </p>
              <p className={styles.bannerDesc}>
                Build, test and deploy your payment solutions quickly and easily
                <br /> with our modular, “building-block” technology.
              </p>
              <Link to='/explore'>
              <Button variant="outline-danger" className={styles.bannerBtn}>
                Explore
              </Button>
              </Link>
            </Fade>
          </div>
          <div className={styles.videoSec}>
            <video
              playsInline="playsInline"
              loop="loop"
              className={styles.introVideo}
              muted="muted"
              autoPlay="autoPlay"
              src={Video}
              type="video/mp4"
            ></video>
          </div>
        </div>
      ) : (
        <Banner2 />
      )}
    </>
  )
}
const useStyles = createUseStyles({
  container: {
    flexDirection: "row",
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    marginTop: 95,
  },
  textPadding: {
    marginTop: 200,
    paddingLeft: 300,
  },
  bannerText: {
    color: "#203864",
    fontSize: 50,
    fontWeight: "bolder",
    marginBottom: 0,

    paddingBottom: 15,
    fontFamily: "Poppins, sansSerif",
  },
  bannerDesc: {
    color: "#203864",
    fontSize: 15,
    paddingBottom: 15,
    fontFamily: "Poppins, sansSerif",
  },
  bannerBtn: {
    width: 130,
    fontSize: 15,
  },
  videoSec: {
    display: "flex",
    justifyContent: "flex-end",
  },
  introVideo: {
    flex: 1,
    width: "110%",
  },
  "@media (max-width: 1550px)": {
    textPadding: {
      marginTop: 100,
      paddingTop: 0,
      paddingLeft: 100,
    },
  },
  "@media (max-width: 1350px)": {
    textPadding: {
      marginTop: 100,
      paddingTop: 0,
      paddingLeft: 100,
    },
    bannerText: {
      fontSize: 40,
    },
    introVideo: {
      width: "100%",
    },
  },
  "@media (max-width: 1150px)": {
    introVideo: {
      width: "80%",
    },
    container: {
      paddingTop: 0,
    },
  },
})
export default Banner
