import React from "react"
import { Button, Container } from "react-bootstrap"
import Video from "../../../assets/Video1.mp4"
import Fade from "react-reveal/Fade"
import useWindowSize from "../../hooks/WindowSize"
import { createUseStyles } from "react-jss"

const Banner2 = () => {
  const styles = useStyles()
  const { width } = useWindowSize()
  return (
    <>
      <div className={styles.videoSec}>
        <video
          playsInline="playsInline"
          loop="loop"
          className={styles.introVideo}
          muted="muted"
          autoPlay="autoPlay"
          src={Video}
          type="video/mp4"
        ></video>
      </div>
      <Container>
        <div className={styles.container}>
          <div>
            <Fade bottom>
              <p className={styles.bannerText}>Easy & Affordable Banking</p>
              <p className={styles.bannerDesc}>
                Build, test and deploy your payment solutions quickly <br /> and
                easily with our modular, “building-block” technology.
              </p>
            </Fade>
            <div className={styles.bannerBtn}>
              <Button variant="outline-danger" style={{ width: "100%" }}>
                Explore
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}
const useStyles = createUseStyles({
  container: {
    flexDirection: "column",
    display: "flex",
    flex: 1,
  },
  bannerText: {
    textAlign: "center",
    color: "#203864",
    fontSize: 30,
    fontWeight: "bolder",
    marginBottom: 0,
    paddingBottom: 15,
    fontFamily: "Poppins, sansSerif",
  },
  bannerDesc: {
    textAlign: "center",
    color: "#203864",
    fontSize: 15,
    paddingBottom: 15,
    fontFamily: "Poppins, sansSerif",
  },
  bannerBtn: {
    justifyContent: "center",
    display: "flex",
    margin: 100,
    marginTop: 0,
    marginBottom: 0,
    fontSize: 15,
  },
  videoSec: {
    marginTop: 100,
  },
  introVideo: {
    width: "100% !important",
  },
})
export default Banner2
