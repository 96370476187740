import React from "react"
import ProCards1 from "../Home/ProCards"

const ProCards = () => {
  return (
    <ProCards1
      T1="Digital Onboarding"
      P1="Take your client onboard seamlessly by our amazing tool of digital onboard process."
      T2="CIB on the Mobile"
      P2="Pioneered in the CIB & Tasdeeq integrations on Web and Mobile as well. Now just in a matter of mili seconds have your CIB & Tasdeeq reports generated"
      T3="Cashflow Analysis"
      P3="Complete Cashflow analysis on the mobile phone. You want it to customize it more you have got it already."
      T4="Workflows & Notifications"
      P4="Instant Synchronization makes it easier to automate the process of loaning with every steps with alerts and notifications."
    />
  )
}

export default ProCards
