import React from "react"
import Footer from "../common/Footer"
import Header from "../common/Header"
import Card from "../data/Card"
import ProCards from "../data/ProCards"
import Banner from "../Home/banner"
import Bottom from "../Home/Bottom"

import Infography from "../Home/Infography"
import Integration from "../Home/Integration"
import PayoasisClick from "../Home/PayoasisClick"
import PayoasisPro from "../Home/PayoasisPro"
import PayoasisX from "../Home/PayoasisX"

import XCard from "../data/xCard"

const Layout = () => {
  return (
    <>
      <Header />
      <Banner />
      <Infography />
      <PayoasisClick />
      <Card />
      <PayoasisPro />
      <ProCards />
      <PayoasisX />
      <XCard />
      <Integration />
      <Bottom />
      <Footer />
    </>
  )
}
export default Layout
