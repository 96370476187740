import React from "react"
import Card1 from "../Home/Card"
const Card = () => {
  return (
    <Card1
      firstTxt="Digital Onboarding"
      firstDesc="Take your client onboard seamlessly by our amazing tool of digital onboard process."
      secTxt="Instant Loan Processing"
      secDesc="Process the loan in just the matter of a few clicks. Simplifying the checklists to ensure we are all set up."
      thirdTxt="AI-Enabled Credit Scoring"
      thirdDesc="Safeguard your loans by our highly in-depth Artificial Intelligence credit scoring mechanism."
      FourthTxt="Extensive APIs"
      FourthDesc="Let us talk to your existing systems with our extensive API libraries.  Everything which is important to make your business a success"
    />
  )
}

export default Card
