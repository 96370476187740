import React from "react"
import { createUseStyles } from "react-jss"
import integ from "../../assets/integ1.png"
import integ2 from "../../assets/integ2.png"
import integ3 from "../../assets/integ3.png"
import integ4 from "../../assets/integ4.png"
import integ5 from "../../assets/integ5.png"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Col, Container } from "react-bootstrap"
import { Row } from "antd"
import { Fade } from "react-reveal"

const Integrration = () => {
  const styles = useStyles()
  return (
    <Container className={styles.top}>
      <div className={styles.heading}>
        <Fade bottom>
          <h1>We have direct integrations with</h1>
        </Fade>
      </div>
      <Fade bottom>
        <Row>
          <Col>
            <LazyLoadImage src={integ} className={styles.img} />
          </Col>
          <Col>
            <LazyLoadImage src={integ2} className={styles.img} />
          </Col>
          <Col>
            <LazyLoadImage src={integ3} className={styles.img} />
          </Col>
          <Col>
            <LazyLoadImage src={integ4} className={styles.img} />
          </Col>
          <Col>
            <LazyLoadImage src={integ5} className={styles.img} />
          </Col>
        </Row>
      </Fade>
    </Container>
  )
}
const useStyles = createUseStyles({
  heading: {
    marginBottom: 100,
    textAlign: "center",
  },
  top: {
    fontFamily: "poppins",
    marginTop: 100,
    alignContent: "center",
  },
  img: { marginRight: 20, alignContent: "center", height: 40 },
})
export default Integrration
