import { Row } from "antd"
import React from "react"
import { Col, Container } from "react-bootstrap"
import { createUseStyles } from "react-jss"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Fade } from "react-reveal"
import click from "../../assets/click.png"
const PayoasisClick = () => {
  const styles = useStyles()
  return (
    <Container>
      <Row className={styles.img}>
        <Col lg={9} md={12} sm={12}>
          <div>
            <Fade bottom>
              <div className={styles.img1}>
                <LazyLoadImage src={click} className={styles.imgtxt} />
              </div>
            </Fade>
          </div>
        </Col>
        {/* <div className={styles.verticalLine}></div> */}
        <Col lg={9} md={12} sm={12}>
          <Fade bottom>
            <p className={styles.text}>
              Payoasis Click is an online cloud-based Web application to manage
              microfinance loaning. Payoasis Click provides you a secure & easy
              to use experience for your users to manage an end-to-end loaning
              process.
            </p>
          </Fade>
        </Col>
      </Row>
    </Container>
  )
}
const useStyles = createUseStyles({
  text: {
    marginTop: 30,
    textAlign: "center",
    alignItems: "center",
    fontFamily: "poppins",
    fontSize: 17,
  },
  img: {
    display: "flex",
    justifyContent: "center",
  },
  img1: { display: "flex", justifyContent: "center" },
  "@media (max-width: 700px)": {
    text: { textAlign: "center" },
    imgtxt: { marginBottom: 50 },
  },
})
export default PayoasisClick
