import React from "react"
import { createUseStyles } from "react-jss"
import { Button, Col, Container, Row } from "react-bootstrap"
import card1 from "../../assets/proCard.png"
import card2 from "../../assets/proCard2.png"
import card3 from "../../assets/proCard3.png"
import card4 from "../../assets/proCard4.png"
import { Fade } from "react-reveal"
import { LazyLoadImage } from "react-lazy-load-image-component"

const ProCards = ({ T1, T2, T3, T4, P1, P2, P3, P4 }) => {
  const styles = useStyles()
  return (
    <>
      <div className={styles.top} style={{ paddingBottom: 50 }}>
        <Container>
          <Fade bottom>
            <Row>
              <Col lg="3" md="6">
                <div className={styles.card}>
                  <div className={styles.img}>
                    <LazyLoadImage src={card1} />
                  </div>
                  <div>
                    <h1 className={styles.cardTitle}>{T1}</h1>
                    <p className={styles.pText}>
                      {P1.length > 80 ? `${P1.substring(0, 80)}...` : P1}
                    </p>
                    <div className={styles.btnDiv}>
                      <Button variant="outline-danger" className={styles.btn}>
                        LEARN MORE
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg="3" md="6">
                <div className={styles.card}>
                  <div className={styles.img}>
                    <LazyLoadImage src={card2} />
                  </div>
                  <div>
                    <h1 className={styles.cardTitle}>{T2}</h1>
                    <p className={styles.pText}>
                      {P2.length > 80 ? `${P2.substring(0, 80)}...` : P2}
                    </p>
                    <div className={styles.btnDiv}>
                      <Button variant="outline-danger" className={styles.btn}>
                        LEARN MORE
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg="3" md="6">
                <div className={styles.card}>
                  <div className={styles.img}>
                    <LazyLoadImage src={card3} />
                  </div>
                  <div>
                    <h1 className={styles.cardTitle}>{T3}</h1>
                    <p className={styles.pText}>
                      {P3.length > 80 ? `${P3.substring(0, 80)}...` : P3}
                    </p>
                    <div className={styles.btnDiv}>
                      <Button variant="outline-danger" className={styles.btn}>
                        LEARN MORE
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg="3" md="6">
                <div className={styles.card}>
                  <div className={styles.img}>
                    <LazyLoadImage src={card4} />
                  </div>
                  <div>
                    <h1 className={styles.cardTitle}>{T4}</h1>
                    <p className={styles.pText}>
                      {P4.length > 80 ? `${P4.substring(0, 80)}...` : P4}
                    </p>
                    <div className={styles.btnDiv}>
                      <Button variant="outline-danger" className={styles.btn}>
                        LEARN MORE
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Fade>
        </Container>
      </div>
    </>
  )
}
const useStyles = createUseStyles({
  top: {
    marginTop: 50,
    fontFamily: "poppins",
  },
  img: {
    marginRight: 30,
  },
  cardTitle: {
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontSize: 17,
    padding: 0,
    marginTop: 10,
    marginBottom: 0,
  },
  pText: {
    textAlign: "center",
    marginTop: 10,
    fontSize: 10,
  },
  btn: {
    width: 150,
    fontSize: 12,
    display: "flex",
    justifyContent: "center",
  },

  btnDiv: {
    fontFamily: "poppins",
    display: "flex",
    justifyContent: "center",
  },
  card: {
    cursor: "pointer",
    display: "flex",
    borderTop: "5px solid #F2655F",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 30,
    boxShadow: " 4px 7px 26px rgba(0, 0, 0, 0.1)",
    background: "white",
    padding: 40,
    borderRadius: 25,
    "&:hover": {
      transform: "scale(1.03)",
      boxShadow: " 4px 7px 26px rgba(0, 0, 0, 0.12)",
    },
  },

  "@media (max-width: 992px)": {
    top: {
      margin: 10,
      marginTop: 80,
    },

    heading: {
      textAlign: "center",
      marginBottom: 50,
    },

    card: {
      marginTop: 20,
    },
  },
  "@media (max-width: 800px)": {
    cardTitle: {
      marginTop: 20,
      fontSize: 19,
      textAlign: "center",
    },
  },

  "@media (max-width: 650px)": {
    card: {
      flexDirection: "column",
    },

    pText: {
      textAlign: "center",
    },
    btn: {
      display: "flex",
      fontSize: 15,
      justifyContent: "center",
    },
    btnDiv: {
      display: "flex",
      justifyContent: "center",
    },
  },
})
export default ProCards
