import React from "react"
import CountUp from "react-countup"
import Fade from "react-reveal/Fade"
import Icon from "../../assets/Iconn1.png"
import Icon2 from "../../assets/Iconn2.png"
import Icon3 from "../../assets/Iconn3.png"
import Icon4 from "../../assets/Iconn4.png"
import { Container, Row, Col } from "react-bootstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { createUseStyles } from "react-jss"

const Infography = () => {
  const styles = useStyles()
  return (
    <Container>
      <Fade bottom>
        <p className="infoHeading">Our Technology Platform</p>
      </Fade>
      <Row className={styles.row}>
        <Col lg="3" sm="6" xs="5">
          <Fade bottom>
            <LazyLoadImage src={Icon} className={styles.iconImg} />
            <CountUp
              end={8000000}
              separator=","
              duration={2}
              className="infoNumber"
            />

            <p className="infoText">Clients Served</p>
          </Fade>
        </Col>

        <Col lg="3" sm="6" xs="5">
          <Fade bottom>
            <LazyLoadImage src={Icon2} className={styles.iconImg} />
            <CountUp
              separator=","
              end={80500000}
              duration={2}
              className="infoNumber"
            />
            <p className="infoText">Loan Disbursed</p>
          </Fade>
        </Col>

        <Col lg="3" sm="6" xs="5">
          <Fade bottom>
            <LazyLoadImage src={Icon3} className={styles.iconImg} />
            <CountUp
              separator=","
              end={70300000}
              duration={2}
              className="infoNumber"
            />

            <p className="infoText">Payment Integrations</p>
          </Fade>
        </Col>

        <Col lg="3" sm="6" xs="5">
          <Fade bottom>
            <LazyLoadImage src={Icon4} className={styles.iconImg} />
            <CountUp
              separator=","
              end={70300000}
              duration={2}
              className="infoNumber"
            />

            <p className="infoText">Experience</p>
          </Fade>
        </Col>
      </Row>
      <div className="mt-5 mb-5">
        <Fade bottom>
          <p className="infoBottom">
            Estimated potential market for microcredit is{" "}
            <span style={{ color: "#a52b26 " }}>
              17.4 million men and women.
            </span>{" "}
            For PKR 10,000
            <br /> and PKR 150,000 loan bracket.
          </p>
        </Fade>
      </div>
    </Container>
  )
}
const useStyles = createUseStyles({
  row: {
    justifyContent: "center",
    textAlign: "center",
  },
  iconImg: { height: "100px" },
})
export default Infography
