import React from "react"
import XCard from "../Home/xCard"

const xCard = () => {
  return (
    <XCard
      T1="Self-onboarding"
      P1="We have gone an extra mile by empowering every individual to apply for a loan in a matter of just couple of minutes."
      T2="E- Disbursement"
      P2="Receive your payment in your digital wallet just like an IBT. All you have a single application to ensure that the payment is received & your payments are made on time."
      T3="Cashflow Analysis"
      P3="Complete Cashflow analysis on the mobile phone. You want it to customize it more you have got it already."
      T4="Workflows & Notifications"
      P4="Instant Synchronization makes it easier to automate the process of loaning with every step with alerts and notifications."
    />
  )
}

export default xCard
